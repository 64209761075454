<template>
  <div class="impressum">
    <Navigation />
    <div class="wrapper">
      <b-container>
        <b-row>
          <b-col class="py-5">
            <h1 class="display-2">Impressum</h1>
            <p>
              Fa. Thai Consult<br />
              Exterstr. 11<br />
              32756 Detmold<br />
              <br />
              Telefon: 05231 – 30 33 270<br />
              www.thai-massage-detmold.de<br />
              <br />
              Steuernummer: 313/5412/3550<br />
              <br />
              Inhaltlich Verantwortlicher gemäß § 6 MDStV:<br />
              Treenoot Töppe (Anschrift wie oben)
            </p>
            <p>
              <b>Haftungshinweis</b>: Trotz sorgfältiger inhaltlicher Kontrolle
              übernehmen wir keine Haftung für die Inhalte externer Links. Für
              den Inhalt der verlinkten Seiten sind ausschließlich deren
              Betreiber verantwortlich.
            </p>
            <h3>Bildquellen</h3>
            <p>
              <strong>
                Aromatherapie Massage:
              </strong>
              <a
                href="https://www.freepik.com/free-photos-vectors/woman%22%3EWoman"
                >photo created by chevanon - www.freepik.com</a
              >
            </p>
            <p>
              <strong>
                Akupressur-Ganzkörpermassage:
              </strong>
              iStock
            </p>
            <p>
              <strong>
                Fußreflexzonenmassage:
              </strong>
              <a
                href="https://www.freepik.com/free-photos-vectors/people%22%3EPeople"
              >
                photo created by jcomp - www.freepik.com</a
              >
            </p>
            <p>
              <strong>
                Hot-Stone-Massage:
              </strong>
              <a
                href="https://www.freepik.com/free-photos-vectors/people%22%3EPeople"
              >
                photo created by pressfoto - www.freepik.com</a
              >
            </p>
            <p>
              <strong>
                Gesichts-/Kopfmassage:
              </strong>
              unsplash
            </p>
            <p>
              <strong>
                Rückenmassage:
              </strong>
              <a
                href="https://www.freepik.com/free-photos-vectors/woman%22%3EWoman"
              >
                iStock</a
              >
            </p>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <Footer />
  </div>
</template>

<script>
import Navigation from "@/components/Navigation.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "impressum",
  components: {
    Navigation,
    Footer
  }
};
</script>

<style scoped lang="scss">
#app {
  .wrapper {
    padding-top: 4.25rem;
    h1 {
      text-indent: 0.3rem;
    }
  }
}
</style>
